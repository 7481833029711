import React, { useEffect, useState } from 'react';
import './redirect.scss';

export default function Redirect(): React.JSX.Element {

    const delay = 20000; //Give them time to read it
    const [countdown, setCountdown] = useState<number>(delay / 1000);
    const currentUrl= window.location.href;
    const newUrl = currentUrl.replace('particity.co.uk', 'helloayda.com');

    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
          window.location.href = newUrl;
        }, delay);

        const countdownInterval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
          }, 1000);
    
        return () => {
            clearTimeout(redirectTimeout);
            clearInterval(countdownInterval);
        };
      }, []);

    const countdownDisplay = countdown > 1 ? countdown : 1;

    return (
        <main>
            <img src="/logo.png" />

            <h1>
                Welcome
            </h1>
            <p>
                Ayda is the new name for Particity.
            </p>    
            <p>
                Our old <strong>particity.co.uk</strong> domain name is no longer in use.
            </p>
            <p>
                Please update your bookmarks to use our new <strong>helloayda.com</strong> domain instead.
            </p>
            <p>
                We will automatically redirect you in {countdownDisplay} seconds.
            </p>

            <div>
                <button onClick={() => {window.location.href = newUrl;}}>Redirect now</button>
            </div>
        </main>
    );
}