import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Redirect from './pages/redirect';

//Create router
const router = createBrowserRouter([
  {
  path: "/",
  element: <App />,
  children: [
    {
      index: true,
      element: <Redirect />
    },
    {
      path: "*",
      element: <Redirect />
    },
  ]
  },

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

